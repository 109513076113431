import React from 'react';
import Intro from 'src/components/_shared/intro/intro';
import SEO from 'src/components/_shared/seo';

const Index = () => (
  <>
    <SEO title="A varsói csata 1920" lang="hu" description="Hogyan mentették meg a lengyelek Európát a kommunizmustól. Multimediális bemutató a világ 18. legfontosabb csatájáról (Külföldiek számára: A legfontosabb csata története, amelyről valószínűleg nem is hallottál)" />
    <Intro locale="hu" />
  </>
);
export default Index;
